.change-userName-form {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .change-userName-form {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .change-userName-form {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .change-userName-form {
    width: 60%;
  }
}

@media (min-width: 1200px) {
  .change-userName-form {
    width: 50%;
  }
}
