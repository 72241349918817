.image-button {
    width: 43px;
    height: 43px;
    padding: 0px;
    border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    margin-left: 5px;
}

.big-button {
    width: 80px;
    height: 80px;
    margin: 0;
}

.small-button {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 80px;
    left: 80px;
    margin: 0;
}

.percent-view {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 8px;
}