.parent-block {
    border-radius: 5px;
    border: 1px solid black;
    display: flex;
    padding: 2px !important;
    margin-left: 10px !important;
    height: 46px;
    max-height: 46px;
    min-height: 46px;
}

.parent-block .tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -8px;
    left: 102%;
}

.parent-block .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent;
  }

.parent-block:hover .tooltiptext {
    visibility: visible;
}
  

.text-block {
    width: calc(100% - 40px);
    margin: 0;
    background-color: inherit;
    color: inherit;
}

.comment-block {
    height: 20%;
    font-size: 11px;
    margin-left: 3px;
    background-color: inherit;
    color: inherit;
}

.innerText-block {
    height: calc(80% - 5px);
    font-size: 18px;
    margin-top: 2px;
    margin-left: 7px;
    background-color: inherit;
    color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.arrow {
    margin: auto;
    font-size: 26px;
    position: absolute;
    right: 4%;
    background-color: inherit;
    color: inherit;
}

.arrow-opened {
    right: 12%;
    transform: rotate(90deg);
    background-color: inherit;
    color: inherit;
}

.arrow-block {
    width: 40px;
    padding: 0;
    margin: 0;
    height: 100%;
    background-color: inherit;
    color: inherit;
}