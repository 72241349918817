.login-success-wrapper,
.login-wrapper {
    background-color: transparent;
}

.login-success-modal,
.login-modal {
    background-color: transparent;
   
}



