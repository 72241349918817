.level-bar-goal {
    background-color: yellow;
}

.title-bar {
    color: #3f007d;
    margin-left: 5px,
}

.level-bar {
    display: flex;
    justify-content: center;
}

.bar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;
    padding: 1px !important;
    width: 9%;
}

.triangle-down {
    width: 0;
    height: 0;
    border-left: 10px;
    border-left-color: transparent;
    border-right: 10px;
    border-right-color: transparent;
    border-top: 10px;
    border-top-color: black;
    border-style: solid;
    border-bottom-style: none;
}

.triangle-up {
    width: 0;
    height: 0;
    border-left: 10px;
    border-left-color: transparent;
    border-right: 10px;
    border-right-color: transparent;
    border-bottom: 10px;
    border-bottom-color: black;
    border-style: solid;
    border-top-style: none;
}

.triangle-none {
    height: 10px;
}

.level-selected {
    border-width: 1px;
    border-color: #FFFF00;
    border-style: solid;
}

.level-item {
    width: 100%;
    height: 18px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 12px;
    padding: 1px;
}

.level-bar-level {
    width: 100%;
    font-size: 13px !important;
    padding: 0px;
    margin: 0px;
    text-align: center;
}