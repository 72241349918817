.question-bar {
  color: grey;
  margin-top: 0px;
  text-align: center;
}

.question-mission-bar {
  color: grey;
  margin-top: 10px;
  text-align: center;
}

.block-row {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.description-block {
  font-style: italic;
  font-size: 16px;
}

.description-left {
  border-right: 1px solid grey;
}

.highlighted {
  color: red;
}

.scroll-bar {
  padding-left: 50px;
  left: 0;
  width: 100% !important;
  height: 400px !important;
  border-top: 4px solid grey;
  border-bottom: 4px solid grey;
}

.map-view-title {
  font-size: 16px;
  color: #d4d2d2;
  text-align: center;
  display: flex;
  padding: 10px;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.manifesto-zone {
  height: 210px;
  text-align: left;
  border-bottom: 1px solid grey;
  margin-bottom: 10px;
}

.manifesto-zone-context {
  height: 210px;
  width: 100%;
}

.end-zone-block {
  background-color: #777777;
}

.end-zone-extra {
  display: flex;
}

.end-zone-extra p {
  background-color: #777777;
  margin-top: 20px;
  margin-bottom: 40px;
  margin-left: 20px;
}
.end-zone-extra a:link {
  color: #ffffff;
}
.end-zone-extra a:visited {
  color: #ffffff;
}
.end-left-zone {
  text-align: center;
}

.end-zone-row {
  display: flex;
  justify-content: space-between;
  color: #ffffff;

  height: 200px;
}

.video-zone {
  margin-top: 70px;
  height: 344px;
}

.treemap-zone {
  width: 100%;
  height: 750px;
  display: inline-block;
  padding: 10px;
  border-bottom: 1px solid grey;
}

@media only screen and (min-width: 750px) {
  .treemap-zone {
    width: 49%;
    display: block;
    border-bottom: 0px solid grey;
  }
  .end-zone-extra p {
    margin: auto;
  }
}
