.sf-2 .dd-row { display: flex; flex-direction: column; }
.sf-2 .dd { width: 100%; margin-bottom: 1em; }
.sf-2 .dd-toggle { width: 100%; display: flex !important; align-items: center; justify-content: space-between; }
.sf-2 .dd-menu { width: 100%; }
.sf-2 .dd-back-btn { width: 50%; }

@media (min-width: 576px) {
    .sf-2 .dd-row { flex-direction: row; justify-content: space-between; align-items: center; }
    .sf-2 .dd { width: 65%; margin-bottom: unset; }
    .sf-2 .dd-back-btn { width: unset; }
}
@media (min-width: 992px) {
    .sf-2 .dd { width: 50%; }
}